/* eslint-disable complexity */
import { omit, isEmpty } from 'lodash';

import {
  convertArrayToString,
  updateCurrentDataBasedOnIngestion,
  formIngestedData,
  checkUserIngestionAction,
} from '../../utils/generic/helper';
import { RegistryDetailsState } from '../../utils/registry/featureInitialState';

const convertDropdownDataforUI = data => ({
  value: data.id,
  label: data.value,
});

export const convertDropdownDataforAPI = data => ({
  id: data.value,
  value: data.label,
});

export const convertTagDropdownDataforUI = (data, key, isSelected) => [
  ...data.map(val => ({
    rowId: val.id,
    id: val[key].id,
    value: val[key].id,
    label: val[key].value,
    isSelected,
  })),
];

export const nameFormation = basicInfo => {
  const { firstName, lastName, middleName, nameSuffix } = basicInfo;
  let combinedName = '';
  if (lastName) {
    combinedName = `${lastName}, ${firstName} ${middleName} ${nameSuffix}`;
  } else {
    combinedName = `${firstName} ${middleName} ${nameSuffix}`;
  }
  return combinedName.trim();
};

export const getRegistryInfoForUI = ({ registryInfo }) => {
  const {
    id = 0,
    reasonForUpdate,
    reasonForUpdateDescription,
    executiveSummary,
    repealedStatus,
  } = registryInfo;

  return {
    // id,
    reasonForUpdate: reasonForUpdate
      ? reasonForUpdate?.[0]
        ? convertDropdownDataforAPI(reasonForUpdate[0])
        : convertDropdownDataforAPI(reasonForUpdate)
      : null,
    updateDesc: reasonForUpdateDescription,
    executiveSummary,
    repealedStatus,
  };
};

export const getRegistryDetailsForUI = registryDetails => {
  const { id, registryInfo } = registryDetails;
  return {
    ...registryDetails,
    registryInfo: registryInfo ? getRegistryInfoForUI(id, registryInfo) : null,
  };
};

export const getRegistryForAPI = ({ contactInfo }) => {
  const {
    // id,
    // reasonForUpdate,
    // reasonForUpdateDescription,
    // executiveSummary,
    contactEmail,
    contactPhone,
    contactInformation,
  } = contactInfo;

  return {
    // id: id || 0,
    // registryInfo: {
    // reasonForUpdate: reasonForUpdate ? convertDropdownDataforAPI(reasonForUpdate) : null,
    // updateDesc:reasonForUpdateDescription,
    // executiveSummary,
    contactEmail,
    contactPhone,
    contactInformation,
    // },
  };
};

export const registryAPIKeys = {
  BASIC_INFO: 'registryInfo',
  CONTACT_INFO: 'contactInfo',
  WHO_ENTER_DATA: 'whoEntersData',
  GENERAL_INFORMATION: 'generalInformation',
  // NOTES: 'notes',
  // PROD_STATUS: 'prodStatusID',
};

export const getPersonStampDates = ({ nextReviewDate, lastFullReviewBy, lastFullReviewDate }) => {
  return {
    nextReviewDate,
    lastFullReviewBy,
    lastFullReviewDate,
  };
};

export const generateNavbarConfig = (id, orgId, headerCountryData) => {
  const navBarConfig = [
    {
      id: 2,
      name: 'REGISTRY ID',
      value: id,
    },
    ...(!orgId || orgId === 0
      ? []
      : [
          {
            id: 3,
            name: 'ORIGINAL PUBLISHED ID',
            value: orgId,
          },
        ]),
    { id: 4, name: 'COUNTRY', value: headerCountryData },
    // {
    //   id: 1,
    //   name: original.reasonForUpdate,
    //   value: original.name,
    // },
  ];
  return navBarConfig;
};
